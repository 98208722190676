import { adminClient } from "@api/admin/AdminClient";
import { DasboardCardComponent } from "@components/admin/dashboards/DashboardCardComponent";
import { DashboardCardRowComponent } from "@components/admin/dashboards/DashboardCardRowComponent";
import { AdminHandedInByShippingProviderCountsGetResponse } from "@reshopper/admin-client";
import { formatNumber } from "@utils/formatting";
import { useEffect, useState } from "react";


export default function HandedInByShippingProviderComponent() {
	const [glsHomeShippingStats, setglsHomeShippingStats] = useState<AdminHandedInByShippingProviderCountsGetResponse>();
	const [glsShopShippingStats, setGlsShopShippingStats] = useState<AdminHandedInByShippingProviderCountsGetResponse>();
	const [instaboxShippingStats, setInstaboxShippingStats] = useState<AdminHandedInByShippingProviderCountsGetResponse>();
	const [daoHomeShippingStats, setDaoHomeShippingStats] = useState<AdminHandedInByShippingProviderCountsGetResponse>();
	const [daoShopShippingStats, setDaoShopShippingStats] = useState<AdminHandedInByShippingProviderCountsGetResponse>();
	const [bringShippingStats, setBringShippingStats] = useState<AdminHandedInByShippingProviderCountsGetResponse>();

	useEffect(() => {
		Promise.all([
			adminClient().adminHandedInByShippingProviderCountsGet("glsHome").then(setglsHomeShippingStats),
			adminClient().adminHandedInByShippingProviderCountsGet("glsShop").then(setGlsShopShippingStats),
			adminClient().adminHandedInByShippingProviderCountsGet("daoHome").then(setDaoHomeShippingStats),
			adminClient().adminHandedInByShippingProviderCountsGet("daoShop").then(setDaoShopShippingStats),
			adminClient().adminHandedInByShippingProviderCountsGet("coolRunnerInstabox").then(setInstaboxShippingStats),
			adminClient().adminHandedInByShippingProviderCountsGet("bring").then(setBringShippingStats),
		])
	}, [])

	return <DasboardCardComponent cardTitle={"LAST 7 DAYS"} sizeNumberDividedWithHundred={3} >
		{glsHomeShippingStats !== undefined && glsShopShippingStats !== undefined &&
			<DashboardCardRowComponent
				text={"GLS"}
				value={formatNumber(glsHomeShippingStats.handedInLastSevenDaysCount + glsShopShippingStats.handedInLastSevenDaysCount)}
			/>
		}
		{daoHomeShippingStats !== undefined && daoShopShippingStats !== undefined &&
			<DashboardCardRowComponent
				text={"Dao"}
				value={formatNumber(daoHomeShippingStats.handedInLastSevenDaysCount + daoShopShippingStats.handedInLastSevenDaysCount)}
			/>
		}
		{instaboxShippingStats !== undefined &&
			<DashboardCardRowComponent
				text={"Instabox"}
				value={formatNumber(instaboxShippingStats.handedInLastSevenDaysCount)}
			/>
		}
		{bringShippingStats !== undefined &&
			<DashboardCardRowComponent
				text={"Bring"}
				value={formatNumber(bringShippingStats.handedInLastSevenDaysCount)}
				isLastRow={true}
			/>
		}
	</DasboardCardComponent>
}